import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../components/layout";
import MyButton from "../components/button";
import { navigate } from "gatsby";
import LoadingResults from "../components/loadingResults";
import { setIsLoading } from "../state/questionsSlice";

const Result = (props) => {
  const results = useSelector((state) => state.questions.answers);
  const isLoading = useSelector((state) => state.questions.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(true));

    setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 6000);

    if (results.length <= 0) {
      navigate("/");
    }
  }, [results, dispatch]);

  if (isLoading) {
    return <LoadingResults />;
  }

  return (
    <Layout pageTitle="النتائج">
      <div style={styles.bButton}>
        هل تبحث عن العلاج؟
        <MyButton onClick={() => navigate("/product")}>اضغط هنا</MyButton>
      </div>

      <div style={styles.body}>
        <h1 style={styles.title}>شكراً لتعبئة الاستمارة</h1>
        <p style={styles.subTitle}>تم تشخيص الاعراض</p>
        <div style={styles.container}>
          {results.map((element, index) => {
            return (
              <div key={element.id} style={styles.cards}>
                <h1 style={styles.questiontitle}>
                  {" "}
                  {index + 1} - {element.questionTitle}
                </h1>
                <span style={styles.answerTitle}>
                  <svg
                    style={styles.answerIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <div>{element.answer}</div>
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};


const styles = {
  body: {
    fontFamily: "bahij",
    padding: 15,
    background: 'linear-gradient(108.64deg, rgba(221, 233, 251, 0.2) 1.55%, rgba(79, 141, 198, 0.149479) 33.91%, rgba(47, 103, 247, 0.101394) 67.69%, rgba(26, 90, 255, 0.1) 67.7%, rgba(13, 119, 216, 0.138) 92.92%)',
  },
  bButton: {
    textAlign: "center",
    padding: "15px 20px",
    position: "fixed",
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255, 0.5)",
    WebkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    fontFamily: "bahij",
    zIndex: 10,
    borderTop: "1px solid rgba(0,0,0,0.1)",
  },
  title: {
    fontSize: "22px",
    textAlign: "center",
  },
  subTitle: {
    textAlign: "center",
  },
  container: {
    marginBottom: "120px",
  },
  cards: {
    borderRadius: "10px",
    margin: "10px 0px",
    padding: "7px 15px",
    backgroundColor: "rgba(255,255,255, 0.3)",
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    border: "1px solid rgba(0,0,0,0.1)"
  },
  questiontitle: {
    fontSize: "18px",
  },
  answerTitle: {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "7px 12px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    color: "rgba(18, 195, 89, 1)",
    borderRadius: "500px",
    fontSize: "17px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px"
  },
  answerIcon: {
    width: "23px",
    marginLeft: "3px",
  },
};

export default Result;
