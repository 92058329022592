import React from "react";
import Layout from "./layout";
import Loading from "../assets/images/loading.gif";

const LoadingResults = () => {
  return (
    <Layout pageTitle="جاري تحميل النتائج">
      <div style={styles.body}>
        <div style={styles.container}>
          <h1 style={styles.title}>جاري تحميل النتيجة</h1>
          <p style={styles.subTitle}>يرجى الانتظار للحظات</p>

          <img style={styles.img} src={Loading} alt="loading" />
        </div>
      </div>
    </Layout>
  );
};

const styles = {
  body: {
    height: "100vh",
    width: "auto",
    display: "flex",
    flexdirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontFamily: "bahij",
    fontWeight: "normal",
    fontSize: "22px",
  },
  subTitle: {
    margin: "auto",
    fontFamily: "bahij",
    fontSize: "16px",
  },
  container: {
    textAlign: "center",
  },
  img:{
      width: "90%",
  }
};

export default LoadingResults;
